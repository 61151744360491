import { Text } from "../text/Text"
import React from "react"

type FormInputProps = {
	inputText?: string
	className?: string
	childClassName?: string
	error?: boolean
	errorText?: string
	children: React.ReactNode
}
const FormInput = ({ children, className, error, errorText, inputText, childClassName }: FormInputProps) => <div className={className}>
	{inputText && <Text className="text-c-neutral">{inputText}</Text>}
	<div className={`mt-2 ${childClassName}`}>
		{children}
	</div>
	{ error && errorText && <div className="flex w-full">
		<Text className="text-c-warning pl-1">{errorText}</Text>
	</div>}
</div>

export default FormInput
