import common from "./common.json"
import offer from "./offer.json"
import custom from "./custom.json"
import provision from "./provision.json"

export const resources = {
	common,
	offer,
	provision,
	custom
}

export default resources
