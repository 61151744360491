import React from "react"
import { UseFormRegisterReturn } from "react-hook-form"

type TextProps = {
	register: UseFormRegisterReturn<any>
	className?: string
	error?: boolean
	placeholder?: string
};


export const Text = ({ register, error, placeholder, className = "" }: TextProps) => <div className="flex w-full bg-neutralLight radius-common">
	<input
		className={`w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal radius-common py-2 focus:outline-none ${error ? "warningForm" : ""} ${className}`}
		{...register}
		placeholder={placeholder}
		type="text"
	/>
</div>
