import React, { useContext, useEffect, useState } from "react"

import { ReportOrder, ReportRealization } from "./content/components"
import { Underline } from "../../../../components/text/underline/underline"
import { DataRepportApi } from "./type"
import { useNavigate, useParams } from "react-router-dom"
import { ReportVehicle } from "./content/vehicle"
import { ReportDetail } from "./content/detail"
import HereMap from "../../../../components/hereMap/HereMap"
import { Button } from "../../../../components/button/Button"
import { authContext } from "../../../../service/api/authContext"
import truck2youLogo from "../../../../assets/commom/truckyou_logo.png"

const Report = () => {
	const navigate = useNavigate()
	const { token, isAdmin } = useContext(authContext)
	const { id } = useParams()
	const [repportData, setRepportData] = useState<DataRepportApi>()
	const [carrierDisplay, setCarrierDisplay] = React.useState(false)

	const handleChange = () => {
		setCarrierDisplay(!carrierDisplay)
	}

	useEffect(() => {
		const fetchData = async() => {
			try {
				let path = `${process.env.REACT_APP_API_DOMAIN}/api/mission/${id}/report`
				if(isAdmin) {
					path += `?display=${carrierDisplay ? "shipper" : "carrier"}`
				}
				const response = await fetch(path, {
					headers: {
						Authorization: `Bearer ${token}`
					},
				})
				if(!response.ok) {
					navigate("/")
				}
				const data: DataRepportApi = await response.json()
				data.command.date = new Date(data.command.date)
				setRepportData(data)
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
			}
		}
		fetchData()
	}, [id, token, carrierDisplay])

	return <div id="main" style={{ marginLeft: 20, backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
		<div className="row space-around m-media-t">
			<div className="row h-center col-media-3 col-s-none col-sm-none col-none">
				<img src={truck2youLogo} alt="logo truck2you"/>
			</div>
		</div>
		<div className="flex mb-10 justify-between">
			<Underline><span className="xtrlarge bold">Rapport de mission</span></Underline>
			{isAdmin && <div className="display-media-none">
				<input checked={carrierDisplay} onChange={handleChange} type="checkbox"/><span>Affichage {carrierDisplay ? "Expéditeur" : "Transporteur"}</span>
			</div>}
			<div className="m-t m-r">
				<Button text="Télécharger le rapport de mission" onClick={() => window.print()} className="col-media-none"/>
			</div>
		</div>
		{repportData && repportData.command && <div className="row m-b-large space-around">
			<div className="col-6 space-between col-media-11">
				<div className="row space-between m-b">
					<div className="row col-5 col-media-5">
						<ReportOrder command={repportData!.command}/>
					</div>
					{repportData.mission && <div className="row col-6 col-media-6">
						<ReportRealization mission={repportData!.mission}/>
					</div>}
				</div>
				<div className="row">
					<div className="row col-12 col-media-12">
						{repportData.vehicle && <ReportVehicle vehicle={repportData.vehicle}/>}
					</div>
				</div>
			</div>
			<div className="row col-5 space-around print:basis-11/12 print:p-2.5">
				{repportData.mapPoints && <HereMap positions={repportData.mapPoints} />}
			</div>
		</div>}
		{repportData?.missionDetail && <div className="row space-around m-l">
			<ReportDetail detail={repportData.missionDetail}/>
		</div>}
	</div>
}
export default Report
