import React from "react"
import { Card } from "../../../../../components/layout/card/Card"
import { DataRepportApi } from "../type"

export type ReportVehicleType = { vehicle: NonNullable<DataRepportApi["vehicle"]> }

export const ReportVehicle = ({ vehicle }: ReportVehicleType) => <Card
	className="row m-media-b basis-full print:basis-full p-3"
	title={"Véhicule & Conducteur"}
	vehicleIcon={{ icon: vehicle.type, size: "large", className: "vehicle-media-medium" }}
>
	<div className="row space-around m-r m-b">
		{vehicle.driver && <div className="row m-r-large">
			<span className="bold m-r">Conducteur:</span>
			<span>{vehicle.driver}</span>
		</div>}
		<div className="row m-r-large">
			<span className="bold m-r">Immatriculation:</span>
			<span>{vehicle.matriculation}</span>
		</div>
		<div className="row m-r-large">
			<span className="bold m-r">Typologie:</span>
			<span>{vehicle.type}</span>
		</div>
	</div>
</Card>
