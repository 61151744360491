/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
const apiUrl = process.env.REACT_APP_API_DOMAIN

// eslint-disable-next-line max-statements
export const checkAuthentication = async(jwtToken: string): Promise<{ isAuthenticated: boolean, isAdmin: boolean }> => {
	try {
		const response = await fetch(`${apiUrl}/api/user/check-auth`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${jwtToken}`
			},
			method: "POST"
		})

		if(!response.ok) {
			throw new Error("Réponse réseau incorrecte")
		}

		const data = await response.json()
		return {
			isAuthenticated: data.isAuthenticated === true,
			isAdmin: data.isAdmin === true
		}
	} catch (error) {
		console.error("Erreur lors de la vérification de l'authentification:", error)
		return {
			isAuthenticated: false,
			isAdmin: false
		}
	}
}
