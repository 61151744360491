import React from "react"

const Divider = () => <div className="relative mt-5 mb-10">
	<div className="absolute h-1 inset-0 flex items-center" aria-hidden="true">
		<div className="w-full flex justify-center">
			<div className="w-4/5 h-1 border-t border-gray-300"/>
		</div>
	</div>
</div>

export default Divider
