/* eslint-disable */
import React, { SetStateAction, Dispatch, useEffect, useState } from "react"
import Switch from "../../../../components/input/switch/Switch"
import { Text } from "../../../../components/text/Text"
import { useTranslation } from "react-i18next"

import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react"
import { FlipIcon, Icon } from "../../../../components/text/icon/icon"
import { Button } from "../../../../components/button/Button"
import { InputHelper } from "../../../../components/input/InputHelper"
import { IframeRequest } from "../../Provision/Provision"
import { MarketApiType } from "../Market"
import Select from "../../../../components/input/select/Select"

type FilterZoneProps = {
	filters: MarketApiType["filters"]
	inputFilters: FilterType,
	sort: {
		filter: string,
		setFilter: Dispatch<SetStateAction<string>>
		filterValue: { [key: string] : string }
	}
	setInputFilters: Dispatch<SetStateAction<FilterType>>
	fullDay: boolean,
	setFullDay: Dispatch<SetStateAction<boolean>>
}

export type FilterType = {
	[familyId: string]: {
		[rulesId: string]: any
	}
}

const FilterZone = ({filters, inputFilters, setInputFilters, fullDay, setFullDay, sort}: FilterZoneProps) => {
	const { t } = useTranslation("provision")

	const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
	const [ filterCount, setFilterCount ] = useState<number>(0)

	const handleResetFilters = () => {
		setFilterCount(0)
		setInputFilters({})
	}
	useEffect(() => {
		setInputFilters({})
	}, [filters])

	const handleInputChange = (type: string, familyId: string, ruleId: string, value: any) => {
		if(type === "choice") {
			if(inputFilters && inputFilters[familyId] && inputFilters[familyId][ruleId]) {
				const element = inputFilters[familyId][ruleId].indexOf(value)
				if(element > -1) {
					value = inputFilters[familyId][ruleId].filter((e: any) => e !== value)
				} else {
					value = [ ...inputFilters[familyId][ruleId], ...[value] ]
				}
			} else {
				value = [ value ]
			}
		}
		setInputFilters({
			...inputFilters,
			[familyId]: { ...inputFilters[familyId], [ruleId]: value }
		})
		setFilterCount(Object.values(inputFilters).reduce((total, currentFamily) => total + Object.keys(currentFamily).length, 0))
	}

	const initFilterValue = (type: string, inputFilters: FilterType, familyId: string, ruleId: string, rules: any) => {
		if(!inputFilters || !inputFilters[familyId] || !inputFilters[familyId][ruleId]) {
			if(type === "number") {
				return [rules.attr.min, rules.attr.max]
			} else if(type === "choice") {
				return [""]
			}
			return 0
		}
		return inputFilters[familyId][ruleId]
	}

	const filterElement = (filters: FilterType[0], familyId: string) => Object.entries(filters)
		.sort(([ruleIdA, ruleA], [ruleIdB, ruleB]) => {
			const positionA = ruleA.rules?.position ?? 0
			const positionB = ruleB.rules?.position ?? 0
			return positionA - positionB
		})
		.map(([ruleId, rule]) => {
		// Modifier l'affichage des filtres ici
		if(rule.rules?.filterable === false || rule.type === "custom") {
			return <div key={ruleId} className="hidden"></div>
		}
		return <div key={ruleId} className="flex flex-col">
			<Text className="ml-6 mb-2" size="medium">{t("market.filter.zone.label", {
				text: rule.label,
				unit: rule.rules?.attr?.unite,
				context: rule.rules?.attr?.unite ? "unit" : null
			})}</Text>
			<div className="ml-12 mt-2 w-2/3">
				<InputHelper
					{...rule}
					value={initFilterValue(rule.type, inputFilters, familyId, ruleId, rule.rules)}
					setValue={(value: any) => handleInputChange(rule.type, familyId, ruleId, value)}
				/>
			</div>
		</div>
	})


	return <div className="lg:w-3/12 flex flex-col lg:mr-5">
		<Dialog className="relative z-40 lg:hidden" open={mobileFiltersOpen} onClose={setMobileFiltersOpen}>
			<div className="fixed inset-0 z-40 flex">
				<DialogPanel
					className="relative ml-auto flex h-full w-8/12 transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
				>
					<div className="flex items-center justify-between px-4">
						<h2 className="text-lg font-medium text-gray-900">{t("market.filter.zone.title")}</h2>
						<button
							type="button"
							className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
							onClick={() => setMobileFiltersOpen(false)}
						>
							<span className="sr-only">Close menu</span>
							<Icon type="cross"/>
						</button>
					</div>

					{/* Filters */}
					<form className="mt-4">
						{Object.entries(filters).map(([familyId, family]) => <Disclosure key={familyId} as="div" className="border-t border-gray-200 pb-4 pt-4">
							{({ open }) => <fieldset>
								<legend className="w-full px-2">
									<DisclosureButton
										className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
										<Text size="big" className="text-c-black">{family.description}</Text>
										<Icon rotate={open} flip={open ? FlipIcon.none : FlipIcon.vertical} type="play" size="xtr-small"/>
									</DisclosureButton>
								</legend>
								<DisclosurePanel className="px-4 pb-2 pt-4">
									<div className="space-y-6">
										{filterElement(family.rules, familyId)}
									</div>
								</DisclosurePanel>
							</fieldset>
							}
						</Disclosure>)}
					</form>
				</DialogPanel>
			</div>
		</Dialog>
		<aside>
			<div className="w-full bg-white radius-common p-2 mb-5">
				<Switch
					buttonState={fullDay}
					setButtonState={setFullDay}
					heightSize={9}
					leftText={t("market.reservation.text", { context: "day" })}
					rightText={t("market.reservation.text", { context: "halfDay" })}
				/>
				<Text size="medium"
					className="flex justify-center text-c-neutral pt-2">{t("market.reservation.description", { context: fullDay ? "day" : "halfDay" })}</Text>
			</div>
			<div className="w-full flex space-around bg-white radius-common p-2 mb-5">
				<div className="flex justify-center items-center w-2/6"><Text size="medium" className="flex justify-center pt-2">Trier par :</Text></div>
				<div className="w-3/6"><Select values={Object.values(sort.filterValue)} setValue={sort.setFilter} selectedValue={[sort.filter]} /></div>
			</div>
			<div className="flex flex-col mb-5 lg:mb-0 radius-common bg-white p-5">
				<div className="lg:mb-5">
					<div className="w-full flex flex-wrap lg:hidden">
						<div className="w-full mb-2 lg:w-1/2 mr-2">
							<Button type="primary" iconType="filter" iconSide="left" text="Filter" onClick={() => setMobileFiltersOpen(true)}/>
						</div>
						<div className="w-full lg:w-1/2">
							{filterCount > 0 && <Button type="neutral" showIcon={false} className="min-w-32 mr-5 border-2 border-c-neutralLight lg:hidden" text={t("market.filter.button.reset", { count: filterCount })} onClick={handleResetFilters}/>}
						</div>
					</div>
					<div className="hidden lg:block">
						<form>
							<div className="flex w-full space-between mb-2">
								<Text size="large">{t("market.filter.zone.title")}</Text>
								{filterCount > 0 && <div className="w-1/2">
									<Button type="neutral" showIcon={false} className="min-w-32 mr-5 border-2 border-c-neutralLight" text={t("market.filter.button.reset", { count: filterCount })} onClick={handleResetFilters}/>
								</div>}
							</div>
							{Object.entries(filters).map(([familyId, family], key) => <Disclosure defaultOpen={key === 0} key={familyId} as="div" className="border-t border-gray-200 pb-4 pt-4">
								{({ open }) => <fieldset>
									<legend className="w-full px-2">
										<DisclosureButton
											className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
											<Text size="big" className="text-c-black">{family.description}</Text>
											<Icon rotate={open} flip={open ? FlipIcon.none : FlipIcon.vertical} type="play" size="xtr-small"/>
										</DisclosureButton>
									</legend>
									<DisclosurePanel className="px-4 pb-2 pt-4">
										<div className="space-y-6">
											{filterElement(family.rules, familyId)}
										</div>
									</DisclosurePanel>
								</fieldset>
								}
							</Disclosure>)}
						</form>
					</div>
				</div>
			</div>
		</aside>
	</div>
}

export default FilterZone
