import React, { ReactNode, useEffect, useState } from "react"
import "./slider.css"
interface SliderProps {
	leftStyle?: React.CSSProperties
	leftClassName?: string
	leftChildren: (alignment: string) => ReactNode
	rightStyle?: React.CSSProperties | undefined
	rightClassName?: string
	rightChildren: (alignment: string) => ReactNode
	parentClassName?: string
}

const Slider = ({
	leftStyle, leftClassName, leftChildren,
	rightStyle, rightClassName, rightChildren,
	parentClassName
}: SliderProps) => {
	const disabledSliderWidth = 700
	const [alignment, setAlignment] = useState<"none" | "left" | "right">("none")
	const [leftSize, setLeftSize] = useState<string>("50%")
	const [rightSize, setRightSize] = useState<string>("50%")

	const updateZoneInformation = (
		leftS = "50%",
		rightS = "50%",
		align: "none" | "left" | "right" = "none"
	) => {
		setLeftSize(leftS)
		setRightSize(rightS)
		setAlignment(align)
	}


	const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)

	useEffect(() => {
		const handleResize = () => {
			setDeviceWidth(window.innerWidth)
		}
		window.addEventListener("resize", handleResize)

		// Nettoyer l'écouteur d'événement lors du démontage du composant
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, []) // Exécuter une fois au montage et nettoyer au démontage

	if(deviceWidth < disabledSliderWidth) {
		return <div className={`flex justify-center flex-col h-full ${parentClassName}`}>
			<div className={`flex ${parentClassName}`}>
				<div className="flex justify-center items-center w-full h-full">
					{leftChildren("none")}
				</div>
			</div>
			<div className={`${rightClassName}`}>
				<div className="flex justify-center items-center w-full h-full">
					{rightChildren("none")}
				</div>
			</div>
		</div>
	}

	return <div className={`flex h-full ${parentClassName}`}>
		<div
			className={`sliderResize ${leftClassName}`}
			style={{ ...{ width: leftSize }, ...leftStyle }}
		>
			<div
				className="flex justify-center items-center w-full h-full"
				onMouseEnter={() => updateZoneInformation("75%", "25%", "left")}
				onMouseLeave={() => updateZoneInformation()}
			>
				{leftChildren(alignment)}
			</div>
		</div>
		<div
			className={`sliderResize ${rightClassName}`}
			style={{ ...{ width: rightSize }, ...rightStyle }}
		>
			<div
				className="flex justify-center items-center w-full h-full"
				onMouseEnter={() => updateZoneInformation("25%", "75%", "right")}
				onMouseLeave={() => updateZoneInformation()}
			>
				{rightChildren(alignment)}
			</div>
		</div>
	</div>
}

export default Slider
