/* eslint-disable */
import React, {CSSProperties} from "react"
import { Icon } from "../../../../components/text/icon/icon"
import { VehiculeIcon } from "../../../../components/vehicule/icon/icon"
import { Text } from "../../../../components/text/Text"
import {Tooltip} from "../../../../components/toolTip/Tooltip";

type FamilyCardProps = {
	icon: string
	label: string
	description: string
	selected: boolean
	setSelected: () => void
	vehicleIcon?: {
		style: CSSProperties
	}
}


export const FamilyCard = (props: FamilyCardProps) => {
	let style: any = {
		backgroundColor: "var(--white)"
	}
	if(props.selected) {
		style = {
			backgroundColor: "var(--primary)",
			borderColor: "var(--primary)"
		}
	}

	return <div style={style} className="w-40 h-40 justify-around rounded-[20px] border-2 flex flex-col items-center text-center p-4"
		onClick={() => props.setSelected()}
	>
		<div className="flex w-full justify-end relative group group-hover:text-gray-700">
			<Tooltip content={props.description}>
				<Icon size="xtr-small" className="relative group-hover:opacity-100" type="question-mark"/>
			</Tooltip>
		</div>
		<VehiculeIcon style={props.vehicleIcon?.style} icon={props.icon} size="none"/>
		<Text size="small" className="mt-2">{props.label}</Text>
	</div>
}
