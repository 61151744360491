import React from "react"
import "./../card.css"
import { Text } from "../../../text/Text"
import { Tooltip } from "../../../toolTip/Tooltip"
import { Icon } from "../../../text/icon/icon"
import { useTranslation } from "react-i18next"

export type QualityType = {
	good: {
		main: string
		description?: string
	}
	high: {
		main: string
		description?: string
	}
	low: {
		main: string
		description?: string
	}
	none: {
		main: string
		description?: string
	}
}

export type QualityMarketProps = {
	selectedQuality?: "good" | "high" | "low" | "none"
	quality: QualityType
}

export const QualityMarket = ({ quality, selectedQuality }: QualityMarketProps) => {
	const { t } = useTranslation()
	if(!selectedQuality) {
		return <></>
	}
	return <div className={"self-end flex items-center mr-[-19px] lg:mr-[-17px]"}>
		{quality[selectedQuality].description && <Tooltip content={t(quality[selectedQuality].description!)}><Icon size="xtr-small" type="question-mark"/></Tooltip>}
		<div className={`${selectedQuality} min-w-56 quality-market p-1 pl-5 ml-2`}>
			<Text className="text-c-white" size="small">{t(quality[selectedQuality].main)}</Text>
		</div>
	</div>
}
