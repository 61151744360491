import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resources from "./type/resource"

i18n.use(initReactI18next)
	// .use(LangagueDetector)
	.init({
		resources,
		lng: "fr", // Langue par défaut
		fallbackLng: "fr",
		interpolation: {
			escapeValue: false,
			format: (value: any, format) => {
				if(format === "kg") {
					return value >= 1000 ? `${value / 1000}t` : `${value}kg`
				}
				return value
			}
		}
	})

export default i18n
