import React, { useEffect, useState } from "react"
import Slider from "../../../components/slider/Slider"
import { Button } from "../../../components/button/Button"
import "./offer.css"
import { Card } from "../../../components/layout/card/Card"
import { useTranslation } from "react-i18next"
import { Text } from "../../../components/text/Text"

const Offer = () => {
	const { t } = useTranslation("offer")

	const goToCustom = (go = false) => {
		window.parent.postMessage({
			goToCustom: go
		}, process.env.REACT_APP_TRUST_DOMAIN as string)
	}
	const goToProvider = (go = false) => {
		window.parent.postMessage({
			goToProvider: go
		}, process.env.REACT_APP_TRUST_DOMAIN as string)
	}

	const [inIframe, setInIframe] = useState(false)
	useEffect(() => {
		if(window.self !== window.top) {
			setInIframe(true)
		}
	}, [])

	return <Slider
		parentClassName={inIframe ? "" : "bg-third"}
		leftChildren={
			(alignment: string): React.ReactNode => <div className="left w-11/12 text-center h-64"><Card type="primary" className={`${inIframe ? "bg-white-opacity-80" : "bg-white"} `}>
				<div className={`min-h-52 pt-3 pl-3 pr-3 flex flex-col ${alignment !== "right" ? "space-around" : "justify-center"}`}>
					<div>
						<Text size="large" className={`offerText ${alignment !== "right" ? "viewFontNoSpace" : "show"}`}>{t("offer.title.custom")}</Text>
						<Text size="large" className={`offerText ${alignment !== "right" ? "show" : "viewFontNoSpace"}`}>{t("offer.title.custom", { context: "full" })}</Text>
					</div>
					{alignment !== "none" && <Text className={`offerText ${alignment === "left" ? "show" : "viewFontNoSpace"}`}>
						{t("offer.description.custom", { context: "full" })}
					</Text>}
					{alignment === "none" && <Text className={`offerText ${alignment === "none" ? "show" : "viewFontNoSpace"}`}>
						{t("offer.description.custom")}
					</Text>}
					<div className={`w-5/6 flex self-center ${alignment === "right" ? "hidden" : ""}`}>
						{inIframe && <Button type="primary" className={`pt-5 offerText ${alignment !== "right" ? "show" : "viewFontNoSpace"}`} text={t("offer.button.custom")} onClick={() => goToCustom(true)}/>}
						{!inIframe && <Button type="primary" className={`pt-5 offerText ${alignment !== "right" ? "show" : "viewFontNoSpace"}`} text={t("offer.button.custom")} to={"/custom"}/>}
					</div>
				</div>
				<div className="h-4">
					<Text size="small" className={`text-c-neutral offerText ${alignment !== "right" ? "show" : "viewFontNoSpace"}`}>*Réponse sous 1 heure ouvrée</Text>
				</div>
			</Card></div>
		}
		rightChildren={
			(alignment: string): React.ReactNode => <div className="right w-11/12 min-h-60 text-center h-64"><Card type="primary"  className={`${inIframe ? "bg-white-opacity-80" : "bg-white"} `}>
				<div className={`min-h-52 pt-3 pl-3 pr-3 flex flex-col ${alignment !== "left" ? "space-around" : "justify-center"}`}>
					<div>
						<Text size="large" className={`offerText ${alignment !== "left" ? "viewFontNoSpace" : "show"}`}>{t("offer.title.provision")}</Text>
						<Text size="large" className={`offerText ${alignment !== "left" ? "show" : "viewFontNoSpace"}`}>{t("offer.title.provision", { context: "full" })}</Text>
					</div>
					{alignment !== "none" && <Text className={`offerText ${alignment === "right" ? "show" : "viewFontNoSpace"}`}>
						{t("offer.description.provision", { context: "full" })}
					</Text>}
					{alignment === "none" && <Text className={`offerText ${alignment === "none" ? "show" : "viewFontNoSpace"}`}>
						{t("offer.description.provision")}
					</Text>}
					<div className={`w-5/6 flex self-center ${alignment === "left" ? "hidden" : ""}`}>
						{inIframe && <Button type="primary" className={`offerText ${alignment !== "left" ? "show" : "viewFontNoSpace"}`} text={t("offer.button.provision")} onClick={() => {
							goToProvider(true)
						}}/>}
						{!inIframe && <Button type="primary" className={`offerText ${alignment !== "left" ? "show" : "viewFontNoSpace"}`} text={t("offer.button.provision")} to={"/provision"}/>}
					</div>
				</div>
				<div className="h-4">
				</div>
			</Card></div>
		}
	/>
}

export default Offer
