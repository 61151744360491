/* eslint-disable max-len */
import React, { CSSProperties } from "react"
import "./icon.css"

export type IconProps = {
	// Todo Optimier ça
	type?: "location" | "leaf" | "star" | "loading" | "unloading" | "cross" | "filter" | "signature" | "dropdown" | "check-line" | "play" | "operation" | "parked" | "warning" | "unknown" | "clock" | "check" | "arrow-left" | "question-mark" | "calendar" | "dot" | "upload" | "useful-width" |	"useful-height" |	"useful-length" | "useful-weight" | "crane-capacity" | string,
	size?: "xtr-small" | "small" | "medium"
	color?: "black" | "none" | "default" | "neutralLight" | "primary" | "primaryDark" | "white" | "second" | "valid" | "validLight" | "warning" | string
	rotate?: boolean
	flip?: string
	style?: CSSProperties
	className?: string
}

// eslint-disable-next-line no-shadow
export enum FlipIcon {
	horizontalVertical = "matrix(-1, 0, 0, -1, 0, 0)",
	horizontal = "matrix(-1, 0, 0, 1, 0, 0)",
	vertical = "matrix(1, 0, 0, -1, 0, 0)",
	none = ""
}

// Icon récupéré sur www.svgrepo.com
// eslint-disable-next-line complexity
export const Icon = ({ type, style, color = "default", size = "medium", className = "", flip = FlipIcon.none, rotate = false }: IconProps) => {
	const finalClassName = `icon icon-${size} icon-${color} ${className}`
	let iconTransform = flip
	if(rotate) {
		iconTransform += " rotate(90)"
	}
	switch(type) {
		case "location":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="-2.4 -2.4 28.80 28.80">
				<path d="M12,1C7.58,1,4,4.58,4,9c0,7.08,8,14,8,14s8-6.92,8-14C20,4.58,16.42,1,12,1z M12,12c-1.66,0-3-1.34-3-3s1.34-3,3-3 s3,1.34,3,3S13.66,12,12,12z"/>
			</svg>
		case "loading":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 256 160">
				<path d="M252,113.004h-5v-22c0-7.4-5.52-13-13-13h-24c-0.5,0-1.1-0.27-1.44-0.61l-37.4-36.4c-1.68-1.68-3.72-2.91-6.16-2.99h-27v92 h40c0.92,0,1.83-1.16,2-2c2.52-14.46,14.83-25.43,30.04-25.43c15.22,0,27.82,11.01,30.34,25.47c0.17,0.92,1.7,1.96,2.62,1.96h2 c4.79,0,9-4.32,9-9.02v-6.22C254,113.834,253.01,113.004,252,113.004z M191,78.004h-41c-1.01,0-2-0.984-2-1.984l-0.04-25.866 c0-1.01,1.03-2.15,2.04-2.15h13c0.5,0,1.52,0.55,1.94,0.89L192,75.004C193.01,76.264,192.6,78.004,191,78.004z M2,2.314v41.05h41 v-17.92l36.25,29.94L43,85.324v-17.96H2v54.08c0,4.48,3.52,8.56,8,8.56h22c0.92,0,1.83-1.16,2-2c2.52-14.46,15.33-24.45,30.54-24.45 c15.21,0,27.82,10.03,30.34,24.49c0.17,0.92,1.2,1.96,2.12,1.96h33v-128L2,2.314z M210.04,110.474c-12.77,0-23.11,10.34-23.11,23.11 c0,12.78,10.34,23.12,23.11,23.12c12.78,0,23.12-10.34,23.12-23.12C233.16,120.814,222.82,110.474,210.04,110.474z M210.04,142.444 c-4.85,0-8.85-4-8.85-8.86c0-4.85,4-8.85,8.85-8.85c4.86,0,8.86,4,8.86,8.85C218.9,138.444,214.9,142.444,210.04,142.444z M64.54,111.464c-12.78,0-23.11,10.33-23.11,23.11c0,12.77,10.33,23.11,23.11,23.11c12.77,0,23.11-10.34,23.11-23.11 C87.65,121.794,77.31,111.464,64.54,111.464z M64.54,143.424c-4.85,0-8.85-4-8.85-8.85c0-4.85,4-8.85,8.85-8.85 c4.85,0,8.85,4,8.85,8.85C73.39,139.424,69.39,143.424,64.54,143.424z"/>
			</svg>
		case "unloading":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 -64 640 640">
				<path d="M50.2 375.6c2.3 8.5 11.1 13.6 19.6 11.3l216.4-58c8.5-2.3 13.6-11.1 11.3-19.6l-49.7-185.5c-2.3-8.5-11.1-13.6-19.6-11.3L151 133.3l24.8 92.7-61.8 16.5-24.8-92.7-77.3 20.7C3.4 172.8-1.7 181.6.6 190.1l49.6 185.5zM384 0c-17.7 0-32 14.3-32 32v323.6L5.9 450c-4.3 1.2-6.8 5.6-5.6 9.8l12.6 46.3c1.2 4.3 5.6 6.8 9.8 5.6l393.7-107.4C418.8 464.1 467.6 512 528 512c61.9 0 112-50.1 112-112V0H384zm144 448c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"/>
			</svg>
		case "signature":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 -32 576 576">
				<path d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z"/>
			</svg>
		case "play":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 24 24">
				<path d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"/>
			</svg>
		case "parked":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 14 14">
				<path d="M 11.714286,1 2.2857143,1 C 1.5758929,1 1,1.57589 1,2.28571 l 0,9.42858 C 1,12.42411 1.5758929,13 2.2857143,13 l 9.4285717,0 C 12.424107,13 13,12.42411 13,11.71429 L 13,2.28571 C 13,1.57589 12.424107,1 11.714286,1 Z m -4.2857146,7.71429 -1.2857143,0 0,1.28571 c 0,0.23571 -0.1928571,0.42857 -0.4285714,0.42857 l -0.8571428,0 C 4.6214286,10.42857 4.4285714,10.23571 4.4285714,10 l 0,-6 c 0,-0.23571 0.1928572,-0.42857 0.4285715,-0.42857 l 2.5714285,0 C 8.8455357,3.57143 10,4.72589 10,6.14286 10,7.55982 8.8455357,8.71429 7.4285714,8.71429 Z m 0,-3.42858 -1.2857143,0 0,1.71429 1.2857143,0 C 7.9,7 8.2857143,6.61429 8.2857143,6.14286 8.2857143,5.67143 7.9,5.28571 7.4285714,5.28571 Z"/>
			</svg>
		case "crane-capacity":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="-66.56 -66.56 645.12 645.12">
				<g id="SVGRepo_bgCarrier" strokeWidth="0">
				</g>
				<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round">
				</g>
				<path d="M426.945,255.166h-48.674l-60.399-60.399c10.673-13.464,17.07-30.464,17.07-48.94c0-12.282-9.956-22.238-22.238-22.238 c-12.282,0-22.238,9.956-22.238,22.238c0,6.188-1.647,11.995-4.514,17.02l-18.158-18.158c-3.128-3.128-7.37-4.886-11.793-4.886 s-8.666,1.758-11.793,4.886l-18.159,18.158c-2.867-5.026-4.514-10.832-4.514-17.02c0-19.004,15.461-34.466,34.466-34.466 c12.282,0,22.238-9.956,22.238-22.238V22.238C278.238,9.956,268.283,0,256.001,0s-22.238,9.956-22.238,22.238v47.835 c-32.737,9.626-56.704,39.943-56.704,75.753c0,18.476,6.397,35.476,17.07,48.94l-60.399,60.399H85.056 c-18.882,0-34.19,15.307-34.19,34.19V477.81c0,18.882,15.307,34.19,34.19,34.19h341.889c18.882,0,34.188-15.307,34.188-34.19 V289.355C461.135,270.472,445.827,255.166,426.945,255.166z M180.903,255.166l39.112-39.113c10.8,5.556,23.027,8.715,35.985,8.715 s25.185-3.159,35.985-8.715l39.113,39.113H180.903z"></path>
			</svg>
		case "useful-width":
			return <svg style={style} transform={iconTransform} className={finalClassName} version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 50 50" xmlSpace="preserve">
				<g>
					<path d="M23.7,16.9c0,4.4,0,22.1,0,26.5c-4,0-8,0-12.1,0c0-2.2,0-17.7,0-19.9c-0.2,0-0.4,0-0.6,0c-0.3,0-0.5,0.3-0.5,0.6
						c0,1.2,0,2.5,0,3.7c0,0.8-0.8,1.4-1.5,1.1c-0.4-0.2-0.7-0.5-0.7-1c0-1.4,0-2.7,0-4.1c0-1.4,1.2-2.5,2.6-2.6c0.2,0,0.4,0,0.7,0
						c0-0.3,0-0.6,0-0.9c0-2.1,1.4-3.5,3.5-3.5c2.8,0,5.5,0,8.3,0C23.4,16.9,23.5,16.9,23.7,16.9z"/>
					<path d="M19.2,43.4c0-4.4,0-22.1,0-26.5c0.1,0,6.9,0,7,0c2.7,0,5.5,0,8.2,0c2.1,0,3.5,1.4,3.5,3.5c0,0.3,0,0.6,0,0.9
						c0.2,0,0.4,0,0.6,0c1.5,0,2.7,1.2,2.7,2.7c0,1.3,0,2.5,0,3.8c0,0.7-0.5,1.2-1.1,1.2c-0.6,0-1.1-0.5-1.1-1.2c0-1.1,0-2.3,0-3.4
						c0-0.9-0.1-1-1.1-1c0,2.2,0,17.7,0,19.9C34,43.4,23.3,43.4,19.2,43.4z"/>
					<path d="M39.1,42.8c0,0.4,0,0.7,0,1c0.1,0,0.3,0.1,0.4,0.1c0.5,0.2,0.8,0.7,0.7,1.2c-0.1,0.5-0.5,0.9-1,1c-0.1,0-0.2,0-0.3,0
						c-9.4,0-18.7,0-28.1,0c-0.2,0-0.3,0-0.5,0c-0.5-0.1-0.9-0.5-0.9-1c0-0.5,0.3-1,0.7-1.1c0.1,0,0.2-0.1,0.3-0.1c0-0.3,0-0.7,0-1
						C20,42.8,29.5,42.8,39.1,42.8z"/>
					<path d="M12.7,45.6c2.6,0,5.1,0,7.7,0c0,0.1,0,0.1,0,0.2c0,0.7,0,1.3,0,2c0,1.2-1,2.2-2.2,2.2c-1.1,0-2.2,0-3.4,0
						c-1.2,0-2.1-0.9-2.2-2.1C12.6,47.2,12.7,46.4,12.7,45.6z"/>
					<path d="M36.9,45.6c0,0.8,0,1.6,0,2.4c-0.1,1.1-1,2-2.1,2c-1.1,0-2.3,0-3.4,0c-1.2,0-2.1-1-2.2-2.2c0-0.7,0-1.5,0-2.2
						C31.8,45.6,34.3,45.6,36.9,45.6z"/>
				</g>
				<line className="cls-1" x1="15" y1="31.2" x2="33.6" y2="31.2" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.83px"/>
				<path fill="#FFF" d="M13,31.2c1.2,0.5,2.7,1.2,3.6,2l-0.8-2l0.8-2C15.7,29.9,14.2,30.8,13,31.2z"/>
				<path fill="#FFF" d="M36.5,31.2c-1.2,0.5-2.7,1.2-3.7,2l0.8-2l-0.8-2C33.8,29.9,35.3,30.8,36.5,31.2z"/>
			</svg>
		case "useful-height":
			return <svg style={style} transform={iconTransform} className={finalClassName} version="1.1" id="Calque_1" x="0px" y="0px"
				viewBox="0 0 50 50" xmlSpace="preserve"
			>
				<path d="M50,46c-0.9,0-1.9,0-2.8,0c-0.1,0-0.3,0.2-0.3,0.4c-0.6,1.9-1.9,3.1-3.8,3.6c-2.4,0.6-5.2-0.9-5.9-3.3
					c-0.1-0.5-0.3-0.6-0.8-0.6c-4.4,0-8.9,0-13.3,0c-0.4,0-0.5,0.1-0.6,0.5c-0.6,2-2,3.2-4,3.5c-2,0.3-3.7-0.4-4.9-2
					c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6,0.8-1.3,1.5-2.2,1.9c-2.7,1.3-6.1-0.2-7-3.1c-0.1-0.4-0.2-0.5-0.7-0.5c-1,0-2,0-3,0
					c-0.2,0-0.3,0-0.5,0c0-1,0-2,0-3c0.4,0,0.8,0,1.3,0c0-0.2,0-0.4,0-0.7c0-7.6,0-15.3,0-22.9c0-0.3,0-0.6,0.1-0.9
					C1.5,17.6,2.2,17,3,16.9c0.2,0,0.4,0,0.6,0c9.1,0,18.2,0,27.3,0c0.3,0,0.6,0,0.9,0.1c0.8,0.2,1.4,0.9,1.5,1.7c0,0.2,0,0.4,0,0.6
					c0,7.6,0,15.2,0,22.9c0,0.2,0,0.4,0,0.6c0.6,0,1.1,0,1.7,0c0-0.2,0-0.4,0-0.6c0-4.6,0-9.2,0-13.7c0-1,0.2-1.2,1.2-1.2
					c2.1,0,4.1,0,6.2,0c0.4,0,0.8,0.1,1.1,0.4c1.7,1.6,3.4,3.2,5,4.8c0.3,0.3,0.5,0.7,0.5,1.1c0,2.9,0,5.8,0,8.6c0,0.2,0,0.4,0,0.6
					c0.4,0,0.7,0,1,0C50,43.9,50,44.9,50,46z M45.6,35.5c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.7-0.4-0.9c-0.8-0.7-1.6-1.4-2.4-2.2
					c-0.6-0.6-1.2-0.9-2-0.8c-1,0.1-1.9,0-2.9,0c0,1.8,0,3.5,0,5.2C40.4,35.5,43,35.5,45.6,35.5z M20.1,44.9c0-1.3-1.1-2.5-2.5-2.5
					c-1.3,0-2.5,1.2-2.5,2.5c0,1.3,1.1,2.5,2.5,2.5C19,47.4,20.1,46.3,20.1,44.9z M44.5,44.9c0-1.4-1.1-2.5-2.4-2.5
					c-1.4,0-2.5,1.1-2.5,2.5c0,1.3,1.1,2.5,2.4,2.5C43.3,47.4,44.5,46.3,44.5,44.9z M11.4,44.9c0-1.3-1.1-2.5-2.5-2.5
					c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5C10.3,47.4,11.4,46.3,11.4,44.9z"/>
				<line stroke="#fff" x1="18.4" y1="36.2" x2="18.4" y2="21.9"/>
				<polygon fill="#fff" points="18.4,40 20.8,34.6 18.4,35.9 16.1,34.6 "/>
				<path fill="#fff" d="M18.4,18.6c0.6,1.5,1.6,3.4,2.7,4.6l-2.7-1l-2.7,1C16.8,22,17.9,20.1,18.4,18.6z"/>
			</svg>
		case "useful-length":
			return <svg style={style} transform={iconTransform} className={finalClassName} x="0px" y="0px" viewBox="0 0 50 50" >
				<path d="M50,46c-0.9,0-1.9,0-2.8,0c-0.1,0-0.3,0.2-0.3,0.4c-0.6,1.9-1.9,3.1-3.8,3.6c-2.4,0.6-5.2-0.9-5.9-3.3
					c-0.1-0.5-0.3-0.6-0.8-0.6c-4.4,0-8.9,0-13.3,0c-0.4,0-0.5,0.1-0.6,0.5c-0.6,2-2,3.2-4,3.5c-2,0.3-3.7-0.4-4.9-2
					c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6,0.8-1.3,1.5-2.2,1.9c-2.7,1.3-6.1-0.2-7-3.1c-0.1-0.4-0.2-0.5-0.7-0.5c-1,0-2,0-3,0
					c-0.2,0-0.3,0-0.5,0c0-1,0-2,0-3c0.4,0,0.8,0,1.3,0c0-0.2,0-0.4,0-0.7c0-7.6,0-15.3,0-22.9c0-0.3,0-0.6,0.1-0.9
					C1.5,17.6,2.2,17,3,16.9c0.2,0,0.4,0,0.6,0c9.1,0,18.2,0,27.3,0c0.3,0,0.6,0,0.9,0.1c0.8,0.2,1.4,0.9,1.5,1.7c0,0.2,0,0.4,0,0.6
					c0,7.6,0,15.2,0,22.9c0,0.2,0,0.4,0,0.6c0.6,0,1.1,0,1.7,0c0-0.2,0-0.4,0-0.6c0-4.6,0-9.2,0-13.7c0-1,0.2-1.2,1.2-1.2
					c2.1,0,4.1,0,6.2,0c0.4,0,0.8,0.1,1.1,0.4c1.7,1.6,3.4,3.2,5,4.8c0.3,0.3,0.5,0.7,0.5,1.1c0,2.9,0,5.8,0,8.6c0,0.2,0,0.4,0,0.6
					c0.4,0,0.7,0,1,0C50,43.9,50,44.9,50,46z M45.6,35.5c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.7-0.4-0.9c-0.8-0.7-1.6-1.4-2.4-2.2
					c-0.6-0.6-1.2-0.9-2-0.8c-1,0.1-1.9,0-2.9,0c0,1.8,0,3.5,0,5.2C40.4,35.5,43,35.5,45.6,35.5z M20.1,44.9c0-1.3-1.1-2.5-2.5-2.5
					c-1.3,0-2.5,1.2-2.5,2.5c0,1.3,1.1,2.5,2.5,2.5C19,47.4,20.1,46.3,20.1,44.9z M44.5,44.9c0-1.4-1.1-2.5-2.4-2.5
					c-1.4,0-2.5,1.1-2.5,2.5c0,1.3,1.1,2.5,2.4,2.5C43.3,47.4,44.5,46.3,44.5,44.9z M11.4,44.9c0-1.3-1.1-2.5-2.5-2.5
					c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5C10.3,47.4,11.4,46.3,11.4,44.9z"
				/>
				<line className="cls-1" x1="6.7" y1="30.2" x2="29.74" y2="30.2" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.83px"/>
				<polygon className="st1" points="2.7,30.3 8.2,32.7 6.8,30.3 8.2,28 " fill="#ffff"/>
				<path className="st1" d="M32.2,30.3c-1.5,0.6-3.4,1.6-4.6,2.7l1-2.7l-1-2.7C28.7,28.7,30.6,29.8,32.2,30.3z" fill="#ffff"/>
			</svg>
		case "useful-weight":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="-87.04 -87.04 686.08 686.08" xmlns="http://www.w3.org/2000/svg">
				<path d="M510.28 445.86l-73.03-292.13c-3.8-15.19-16.44-25.72-30.87-25.72h-60.25c3.57-10.05 5.88-20.72 5.88-32 0-53.02-42.98-96-96-96s-96 42.98-96 96c0 11.28 2.3 21.95 5.88 32h-60.25c-14.43 0-27.08 10.54-30.87 25.72L1.72 445.86C-6.61 479.17 16.38 512 48.03 512h415.95c31.64 0 54.63-32.83 46.3-66.14zM256 128c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z"/>
			</svg>
		case "operation":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 453.322 453.322">
				<path d="M420.806,255.459c-1.61-0.338-3.132-0.657-4.488-1.009c-14.98-3.896-22.413-6.216-27.62-16.269l-19.555-39.592 c-6.244-12.055-22.368-21.863-35.942-21.863h-35.087v-26.971c0-8.063-6.56-14.622-14.625-14.622l-83.815,0.088 c-1.991-53.33-45.989-96.104-99.798-96.104C44.804,39.116,0,83.921,0,138.993c0,31.138,14.326,58.99,36.729,77.321V351.91 c0,8.063,6.56,14.622,14.622,14.622h25.463c3.813,26.908,26.99,47.674,54.937,47.674c27.949,0,51.127-20.766,54.939-47.674h92.821 c0.606,0,1.227-0.034,1.851-0.081c3.777,26.948,26.971,47.755,54.946,47.755c27.949,0,51.128-20.766,54.94-47.674h36.472 c14.117,0,25.602-11.484,25.602-25.6v-43.892C453.322,262.281,433.754,258.176,420.806,255.459z M99.876,69.116 c38.529,0,69.876,31.346,69.876,69.876s-31.347,69.876-69.876,69.876S30,177.522,30,138.993S61.347,69.116,99.876,69.116z M131.751,379.252c-11.324,0-20.537-9.213-20.537-20.538c0-11.325,9.213-20.538,20.537-20.538c11.328,0,20.545,9.213,20.545,20.538 C152.296,370.039,143.079,379.252,131.751,379.252z M336.309,379.252c-11.323,0-20.536-9.213-20.536-20.538 c0-11.325,9.213-20.538,20.536-20.538c11.329,0,20.546,9.213,20.546,20.538C356.854,370.039,347.638,379.252,336.309,379.252z M298.113,252.312v-54.525h26.876c10.315,0,23.04,7.74,27.766,16.859l17.401,35.232c0.443,0.857,0.907,1.667,1.394,2.435H298.113z"></path>
				<path d="M100.933,156.728l26.065,8.259c1.507,0.478,3.033,0.705,4.534,0.705c6.364,0,12.271-4.083,14.296-10.473 c2.502-7.897-1.872-16.328-9.77-18.83l-21.021-6.66l-2.201-2.798v-19.065c0-8.284-6.716-15-15-15s-15,6.716-15,15v24.147 C82.837,143.011,90.447,153.406,100.933,156.728z"/>
			</svg>
		case "clock":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 24 24">
				<path d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z"/>
			</svg>
		case "warning":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 458.096 458.096">
				<path d="M454.106,396.635L247.33,38.496c-3.783-6.555-10.775-10.592-18.344-10.592c-7.566,0-14.561,4.037-18.344,10.592 L2.837,398.414c-3.783,6.555-3.783,14.629,0,21.184c3.783,6.556,10.778,10.593,18.344,10.593h415.613c0.041,0,0.088,0.006,0.118,0 c11.709,0,21.184-9.481,21.184-21.185C458.096,404.384,456.612,400.116,454.106,396.635z M57.872,387.822L228.986,91.456 L400.1,387.828H57.872V387.822z M218.054,163.009h21.982c1.803,0,3.534,0.727,4.8,2.021c1.259,1.3,1.938,3.044,1.892,4.855 l-4.416,138.673c-0.095,3.641-3.073,6.537-6.703,6.537h-13.125c-3.635,0-6.614-2.902-6.7-6.537l-4.418-138.673 c-0.047-1.812,0.636-3.555,1.895-4.855C214.52,163.736,216.251,163.009,218.054,163.009z M246.449,333.502v25.104 c0,3.699-2.997,6.696-6.703,6.696h-21.394c-3.706,0-6.7-2.997-6.7-6.696v-25.104c0-3.7,2.994-6.703,6.7-6.703h21.394 C243.452,326.793,246.449,329.802,246.449,333.502z"></path>
			</svg>
		case "check":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 16 16">
				<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
			</svg>
		case "check-line":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 20 20">
				<path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
			</svg>
		case "arrow-left":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 512 512">
				<path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"/>
			</svg>
		case "filter":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 24 24">
				<path d="M9.5 7C9.5 8.93 7.93 10.5 6 10.5C4.07 10.5 2.5 8.93 2.5 7C2.5 5.07 4.07 3.5 6 3.5C7.93 3.5 9.5 5.07 9.5 7ZM18 13.5C16.07 13.5 14.5 15.07 14.5 17C14.5 18.93 16.07 20.5 18 20.5C19.93 20.5 21.5 18.93 21.5 17C21.5 15.07 19.93 13.5 18 13.5ZM21.75 7C21.75 6.586 21.414 6.25 21 6.25H15C14.586 6.25 14.25 6.586 14.25 7C14.25 7.414 14.586 7.75 15 7.75H21C21.414 7.75 21.75 7.414 21.75 7ZM9.75 17C9.75 16.586 9.414 16.25 9 16.25H3C2.586 16.25 2.25 16.586 2.25 17C2.25 17.414 2.586 17.75 3 17.75H9C9.414 17.75 9.75 17.414 9.75 17Z"/>
			</svg>
		case "question-mark":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 512 512">
				<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
			</svg>
		// case "barre de bateau":
		// return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 512 512">
		//	<path d="M385.1 419.1C349.7 447.2 304.8 464 256 464s-93.7-16.8-129.1-44.9l80.4-80.4c14.3 8.4 31 13.3 48.8 13.3s34.5-4.8 48.8-13.3l80.4 80.4zm68.1 .2C489.9 374.9 512 318.1 512 256s-22.1-118.9-58.8-163.3L465 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L419.3 58.8C374.9 22.1 318.1 0 256 0S137.1 22.1 92.7 58.8L81 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L58.8 92.7C22.1 137.1 0 193.9 0 256s22.1 118.9 58.8 163.3L47 431c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l11.8-11.8C137.1 489.9 193.9 512 256 512s118.9-22.1 163.3-58.8L431 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-11.8-11.8zm-34.1-34.1l-80.4-80.4c8.4-14.3 13.3-31 13.3-48.8s-4.8-34.5-13.3-48.8l80.4-80.4C447.2 162.3 464 207.2 464 256s-16.8 93.7-44.9 129.1zM385.1 92.9l-80.4 80.4c-14.3-8.4-31-13.3-48.8-13.3s-34.5 4.8-48.8 13.3L126.9 92.9C162.3 64.8 207.2 48 256 48s93.7 16.8 129.1 44.9zM173.3 304.8L92.9 385.1C64.8 349.7 48 304.8 48 256s16.8-93.7 44.9-129.1l80.4 80.4c-8.4 14.3-13.3 31-13.3 48.8s4.8 34.5 13.3 48.8zM208 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"/>
		// </svg>
		case "calendar":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 24 24" fill="none">
				<path d="M7.75 2.5C7.75 2.08579 7.41421 1.75 7 1.75C6.58579 1.75 6.25 2.08579 6.25 2.5V4.07926C4.81067 4.19451 3.86577 4.47737 3.17157 5.17157C2.47737 5.86577 2.19451 6.81067 2.07926 8.25H21.9207C21.8055 6.81067 21.5226 5.86577 20.8284 5.17157C20.1342 4.47737 19.1893 4.19451 17.75 4.07926V2.5C17.75 2.08579 17.4142 1.75 17 1.75C16.5858 1.75 16.25 2.08579 16.25 2.5V4.0129C15.5847 4 14.839 4 14 4H10C9.16097 4 8.41527 4 7.75 4.0129V2.5Z"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12C2 11.161 2 10.4153 2.0129 9.75H21.9871C22 10.4153 22 11.161 22 12ZM16 13.25C16.4142 13.25 16.75 13.5858 16.75 14V15.25L18 15.25C18.4142 15.25 18.75 15.5858 18.75 16C18.75 16.4142 18.4142 16.75 18 16.75H16.75V18C16.75 18.4142 16.4142 18.75 16 18.75C15.5858 18.75 15.25 18.4142 15.25 18V16.75L14 16.75C13.5858 16.75 13.25 16.4142 13.25 16C13.25 15.5858 13.5858 15.25 14 15.25H15.25V14C15.25 13.5858 15.5858 13.25 16 13.25Z"/>
			</svg>
		case "dropdown":
			return <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
				<path fillRule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clipRule="evenodd"/>
			</svg>
		case "dot":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 31.955 31.955">
				<path d="M27.25,4.655C20.996-1.571,10.88-1.546,4.656,4.706C-1.571,10.96-1.548,21.076,4.705,27.3 c6.256,6.226,16.374,6.203,22.597-0.051C33.526,20.995,33.505,10.878,27.25,4.655z"></path>
			</svg>
		case "cross":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 15 15">
				<path d="M2.64,1.27L7.5,6.13l4.84-4.84C12.5114,1.1076,12.7497,1.0029,13,1c0.5523,0,1,0.4477,1,1,c0.0047,0.2478-0.093,0.4866-0.27,0.66L8.84,7.5l4.89,4.89c0.1648,0.1612,0.2615,0.3796,0.27,0.61c0,0.5523-0.4477,1-1,1,c-0.2577,0.0107-0.508-0.0873-0.69-0.27L7.5,8.87l-4.85,4.85C2.4793,13.8963,2.2453,13.9971,2,14c-0.5523,0-1-0.4477-1-1,c-0.0047-0.2478,0.093-0.4866,0.27-0.66L6.16,7.5L1.27,2.61C1.1052,2.4488,1.0085,2.2304,1,2c0-0.5523,0.4477-1,1-1,C2.2404,1.0029,2.4701,1.0998,2.64,1.27z"/>
			</svg>
		case "leaf":
			return <svg style={style} transform={iconTransform} className={`leaf ${finalClassName}`} viewBox="0 0 24 24">
				<path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/>
			</svg>
		case "star":
			return <svg style={style} transform={iconTransform} className={`star ${finalClassName}`} viewBox="0 0 24 24">
				<path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
			</svg>
		case "upload":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 20 17">
				<path
					d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
			</svg>
		case "trash":
			return <svg style={style} transform={iconTransform} className={finalClassName} viewBox="0 0 24 24">
				<path d="M20.75 6C20.75 6.414 20.414 6.75 20 6.75H4C3.586 6.75 3.25 6.414 3.25 6C3.25 5.586 3.586 5.25 4 5.25H8.214C8.307 5.068 8.37899 4.862 8.45599 4.632L8.658 4.02499C8.862 3.41299 9.43499 3 10.081 3H13.919C14.565 3 15.138 3.41299 15.342 4.02499L15.544 4.632C15.621 4.862 15.693 5.068 15.786 5.25H20C20.414 5.25 20.75 5.586 20.75 6ZM18.56 7.75C18.733 7.75 18.871 7.89701 18.859 8.07001L18.19 18.2C18.08 19.78 17.25 21 15.19 21H8.81C6.75 21 5.92 19.78 5.81 18.2L5.141 8.07001C5.13 7.89701 5.267 7.75 5.44 7.75H18.56ZM10.75 11C10.75 10.59 10.41 10.25 10 10.25C9.59 10.25 9.25 10.59 9.25 11V16C9.25 16.41 9.59 16.75 10 16.75C10.41 16.75 10.75 16.41 10.75 16V11ZM14.75 11C14.75 10.59 14.41 10.25 14 10.25C13.59 10.25 13.25 10.59 13.25 11V16C13.25 16.41 13.59 16.75 14 16.75C14.41 16.75 14.75 16.41 14.75 16V11Z"/>
			</svg>
		default:
			return <svg style={style} transform={iconTransform} className={finalClassName}>
				<path
					d="M2 10H4V12H2V10M18 10H20V12H18V10M10 2H12V4H10V2M10 18H12V20H10V18M6 18H8V20H6V18M2 14H4V16H2V14M2 18H4V20H2V18M2 2H4V4H2V2M2 6H4V8H2V6M6 2H8V4H6V2M14 2H16V4H14V2M18 2H20V4H18V2M18 6H20V8H18V6M14 18H16V20H14V18M18 18H20V20H18V18M18 14H20V16H18V14Z"/>
			</svg>
	}
}

/*

*/

