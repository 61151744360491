import React, { CSSProperties } from "react"
import "./icon.css"

export type VehicleIconProps = {
	icon?: "Plateau" | "Benne&G" | "BennePl" | "BenneVl" | "Fourgon" | "PLSC" | "Ampli&G" | "Tole" | "PlCity&G" | "CGV" | "Pl&G" | string
	size?: "none" | "small" | "medium" | "large"
	className?: string,
	style?: CSSProperties
}

export const VehiculeIcon = ({ icon, style, size = "medium", className = "" }: VehicleIconProps) => {
	const finalClassName = `vehicleIcon vehicle-${size} ${className}`
	switch(icon) {
		case "Plateau":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1606939733/Pictogrammes%20appli/V%C3%A9hicules/Camion_plateau.svg"/>
		case "Benne&G":
		case "Benne":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1606949258/Pictogrammes%20appli/V%C3%A9hicules/benne_et_grue.svg"/>
		case "BennePl":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1606943372/Pictogrammes%20appli/V%C3%A9hicules/camion_benne_dquzqu.svg"/>
		case "BenneVl":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1708944052/Pictogrammes%20appli/V%C3%A9hicules/fourgon-benne.svg"/>
		case "Fourgon":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1606939733/Pictogrammes%20appli/V%C3%A9hicules/Fourgon.svg"/>
		case "PLSC":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1606912382/Pictogrammes%20appli/V%C3%A9hicules/PLSC.svg"/>
		case "Ampli&G":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1606947862/Pictogrammes%20appli/V%C3%A9hicules/ampli_roll_et_grue.svg"/>
		case "Tole":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1708944052/Pictogrammes%20appli/V%C3%A9hicules/tole%CC%81-20m3.svg"/>
		case "PlCity&G":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1606942866/Pictogrammes%20appli/V%C3%A9hicules/tracteur_grue_plateau_city.svg"/>
		case "CGV":
			return <img className={finalClassName} style={style} src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1708944053/Pictogrammes%20appli/V%C3%A9hicules/caisse-grand-volume.svg"/>
		case "Pl&G":
		default:
			return <img className={finalClassName} style={style} src={icon}/>
	}
}
