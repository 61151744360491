/* eslint-disable no-empty-function, @typescript-eslint/no-empty-function */
import  React, { ReactNode, createContext, useContext, useState } from "react"

type AuthContextType = {
	token: string | null
	isAdmin: boolean
	login: (newToken: string) => void
	grantAdmin: (isAdminResponse: boolean) => void,
	logout: () => void
}

export const authContext = createContext<AuthContextType>({
	token: "",
	isAdmin: false,
	login: () => {},
	grantAdmin: () => {},
	logout: () => {}
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [token, setToken] = useState<string | null>(null)
	const [isAdmin, setIsAdmin] = useState<boolean>(false)

	const login = (newToken: string) => {
		setToken(newToken)
		setIsAdmin(false)
	}

	const logout = () => {
		setToken(null)
		setIsAdmin(false)
	}

	const grantAdmin = (isAdminResponse: boolean) => {
		setIsAdmin(isAdminResponse)
	}

	return (
		<authContext.Provider value={{ token, isAdmin, login, logout, grantAdmin }}>
			{children}
		</authContext.Provider>
	)
}

export const useAuth = (): AuthContextType => {
	const context = useContext(authContext)
	if(!context) {
		throw new Error("useAuth must be used within an AuthProvider")
	}
	return context
}