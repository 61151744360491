import React from "react"
import { Card } from "../../../../../components/layout/card/Card"
import { Icon } from "../../../../../components/text/icon/icon"
import { DataRepportApi } from "../type"


export const ReportRealization = ({ mission }: { mission: DataRepportApi["mission"]}) => <Card
	title={"Réalisation"}
	className="col-m-11 p-3"
>
	<div className="row m-b print:m-0">
		<div className="basis-full print:basis-full mb-2 print:m-0">
			{mission!.firstOperation && <div className="flex flex-col">
				<span className="bold p-r-10">1er chargement : </span>
				<div className="flex flex-row items-ends">
					<Icon type="clock" size="xtr-small" className="mr-2"/><span className="lineHeight-media-small">{mission!.firstOperation.date}</span>
				</div>
				<div className="flex flex-row items-ends">
					<Icon type="location" size="xtr-small" className="mr-2"/><span className="lineHeight-media-small">{mission!.firstOperation.location}</span>
				</div>
			</div>}
		</div>
		<div className="basis-full print:basis-full mb-2 print:m-0">
			{mission!.lastOperation && <div className="flex flex-col">
				<span className="bold p-r-10">Fin de dernière opération : </span>
				<div className="flex flex-row items-ends">
					<Icon type="clock" size="xtr-small" className="mr-2"/><span className="lineHeight-media-small">{mission!.lastOperation.date}</span>
				</div>
				<div className="flex flex-row items-ends">
					<Icon type="location" size="xtr-small" className="mr-2"/><span className="lineHeight-media-small">{mission!.lastOperation.location}</span>
				</div>
			</div>}
		</div>
		{mission!.nbPackage > 1 && <div className="col-12 col-media-12 m-b print:m-0">
			<div className="row">
				<div className="col-5 col-media-5">
					<span className="bold inline m-r">Livraison{mission!.nbPackage > 1 ? "s" : ""} :</span>
				</div>
				<div className="col-7 col-media-7">
					<span>{mission!.nbPackage}</span>
				</div>
			</div>
		</div>}
		{mission!.duration && <div className="col-12 col-media-12 m-b print:m-0">
			<div className="row">
				<div className="col-5 col-media-5">
					<span className="bold inline m-r">Durée réalisée :</span>
				</div>
				<div className="col-7 col-media-7">
					<span>{mission!.duration}</span>
				</div>
			</div>
		</div>}
		{mission!.distance && <div className="col-12 col-media-12 m-b print:m-0">
			<div className="row">
				<div className="col-5 col-media-5">
					<span className="bold inline m-r">Distance parcourue :</span>
				</div>
				<div className="col-7 col-media-7">
					<span>{mission!.distance}</span>
				</div>
			</div>
		</div>}
		{mission!.extra && mission!.extra.length > 0 && <div className="col-12 col-media-12 m-b print:m-0">
			<div className="row">
				<div className="col-5 col-media-5">
					<span className="bold inline">{ mission!.extra.length > 1 ? "Suppléments à facturer :" : "Supplément à facturer :"}</span>
				</div>
				<div className="col-7 col-media-7">
					<ul className="m-reset">
						{mission!.extra.map((elements, index) => <li style={{ paddingBottom: 5 }} key={index}>{elements}</li>)}
					</ul>
				</div>
			</div>
		</div>}
	</div>
</Card>
